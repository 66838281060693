<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 인계일 -->
          <c-datepicker
            :range="true"
            label="인계기간"
            name="allbaroDts"
            v-model="searchParam.allbaroDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="폐기물 처리현황 목록"
      tableId="processManual"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="올바로데이터 업로드" icon="add" @btnClicked="openUpload" />
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'waste-report-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'allbaroDt',
            field: 'allbaroDt',
            label: '인계일',
            align: 'center',
            sortable: true,
          },
          {
            name: 'envWasteAllbaroNo',
            field: 'envWasteAllbaroNo',
            label: '인계번호',
            align: 'center',
            sortable: true,
            type: 'link'
          },
          {
            name: 'wasteType',
            field: 'wasteType',
            label: '폐기물 종류(성상)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'wasteVolume',
            field: 'wasteVolume',
            label: '위탁량',
            align: 'center',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'wasteVolumeUnit',
            field: 'wasteVolumeUnit',
            label: 'LBLUNIT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'transportName',
            field: 'transportName',
            label: '운반자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '처리자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processMethod',
            field: 'processMethod',
            label: '처리방법',
            align: 'center',
            sortable: true,
          },
          {
            name: 'transferName',
            field: 'transferName',
            label: '인계자명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        allbaroDts: [],
        plantCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.waste.allbaro.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    openUpload() {
      this.popupOptions.target = () => import(`${"./wasteReportUpload.vue"}`);
      this.popupOptions.title = '폐기물 처리결과 업로드(올바로)';
      this.popupOptions.param = {
        plantCd: this.searchParam.plantCd,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./wasteReportManageDetail.vue"}`);
      this.popupOptions.title = '폐기물 처리현황 상세';
      this.popupOptions.param = {
        envWasteAllbaroId: result ? result.envWasteAllbaroId : '',
        plantCd: result ? result.plantCd : this.searchParam.plantCd,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    closeExcelUploadPopup(_result, plantCd) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.userId = this.$store.getters.user.userId; 
          item.plantCd = plantCd;
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.env.waste.allbaro.excel.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    }
  }
};
</script>
